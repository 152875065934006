import React from 'react'
import styled from 'styled-components'
import { Anchor, Box } from 'grommet'
import { useStaticQuery, graphql } from 'gatsby'
import { OfflineStorage } from 'grommet-icons'
import { DocsFooterContent, DocsLayout, DocsMarkdown, DocsNav, DocsSearchBar, DocsTitle, DocsNavIndex } from '../../components'

const Docs = ({ location }) => {
  const data = useStaticQuery(graphql`
    query DocsIndexQuery {
      page: markdownRemark(fields: {slug: {eq: "/docs/overview/home/"}}) {
        id
        frontmatter {
          title
          description
        }
        html
      }
      pages: allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: {eq: "docs"},
              category: {nin: ["Legal", "Security"]},
            }
          }, 
          sort: {
            fields: [frontmatter___category, frontmatter___order, frontmatter___date], 
            order: [ASC, ASC, DESC]
          }
        ) {
        edges {
          next {
            fields {
              slug
            }
            frontmatter {
              title
              order
            }
          }
          previous {
            fields {
              slug
            }
            frontmatter {
              title
              order
            }
          }
          post: node {
            id
            frontmatter {
              title
              description
              category
              date
              relativeDate: date(fromNow: true)
              order
            }
            fields {
              slug
            }
            excerpt(pruneLength: 160)
            html
          }
        }
      }
      categories: allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: {eq: "docs-category"},
              title: {nin: ["Legal", "Security"]},
            }
          }, 
          sort: {
            fields: [frontmatter___order], 
            order: [ASC]
          }
        ) {
        edges {
          category: node {
            id
            frontmatter {
              title
              order
            }
            fields {
              slug
            }
          }
        }
      }
      menuLinks: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: {eq: "docs-menu-links"}
          }
        }, 
        sort: {
          fields: [frontmatter___order], 
          order: [ASC]
        }
      ) {
      edges {
        link: node {
          frontmatter {
            title
            path
            order
          }
        }
      }
    }
    }
  `)

  const { title, description } = data.page.frontmatter
  const categoryData = data.categories.edges
  const pageData = data.pages.edges
  const menuLinks = data.menuLinks.edges

  return (
    <DocsLayout 
      location={location}
      title="Documentation"
      titleTemplate="Hippobyte Platform"
      description="Hippobyte automated ETL documentation. SaaS applications, databases, and files automatically synchronized to your data warehouse."
    >
      <DocsNav categoryData={categoryData} pageData={pageData} menuLinks={menuLinks} />
      <Box 
        as="section"
        gridArea="main"
        direction="column" 
        pad={{
          left: "small",
          right: "6em"
        }} 
      >
        <ContentContainer>
          <SearchContainer 
            direction="row" 
            align="center" 
            background="white"
            margin={{ left: "medium" }}
            pad={{ top: "small", bottom: "14px" }}
          >
            <Box basis="3/4" pad={{ right: "large" }}>
              <DocsSearchBar data={{ page: data.page, pages: pageData, categories: categoryData }} />
            </Box>
            <Box basis="1/4" align="start">
              <ConsoleLink label="Launch Console" icon={<OfflineStorage />} reverse href="https://console.hippobyte.com/" />
            </Box>
          </SearchContainer>
          <DocsTitle
            title={title}
            description={description}
          />
          <Box pad={{ horizontal: "medium" }}>
            <DocsMarkdown 
              dangerouslySetInnerHTML={{ __html: data.page.html }}   
            />
            <DocsNavIndex />
            <DocsFooterContent />
          </Box>
        </ContentContainer>
      </Box>
    </DocsLayout>
  )
}

const ContentContainer = styled(Box)`
  position: relative;
`

const SearchContainer = styled(Box)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
`

const ConsoleLink = styled(Anchor)`
  font-weight: 600;
  font-size: .955em;
`

export default Docs